.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 150%;
    overflow-x: auto;
}

table {
    width: fit-content;
    min-width: 100%;
}

.center-align {
    text-align: center;
}

.narrow {
    width: 50px;
    text-align: center;
}


.report-td {
    text-align: center;
    vertical-align: middle;
}